import { useReducer } from 'react';
import type { MotionItem } from '~/models/MotionItem';

/* GEN MOTION STATE */

export type GenMotionState = {
  processing: boolean;
  prompt?: string | null;
  progress: number;
  data?: MotionItem | null;
  motionData?: any | null;
  sessionId?: string | null;
  error?: string | null;
};

export type GenMotionAction =
  | { type: 'reset', }
  | { type: 'request', prompt: string }
  | { type: 'update-prompt', prompt: string }
  | { type: 'update-sessionid', sessionId: string }
  | { type: 'update-progress', progress: number }
  | { type: 'success', data: MotionItem }
  | { type: 'failure', error: string }

function genMotionReducer(state: GenMotionState, action: GenMotionAction): GenMotionState {
  switch (action.type) {
    case 'reset':
      return initialGenMotionState;
    case 'request':
      return { processing: true, progress: 0, prompt: action.prompt };
    case 'update-prompt':
      return { ...state, prompt: action.prompt };
    case 'update-sessionid':
      return { ...state, sessionId: action.sessionId };
    case 'update-progress':
      return { ...state, progress: action.progress };
    case 'success':
      return { ...state, processing: false, data: action.data };
    case 'failure':
      return { ...state, processing: false, error: action.error };
  }
};

const initialGenMotionState: GenMotionState = {
  processing: false,
  progress: 0,
  prompt: '',
  data: null,
  error: null,
};


export function useGenMotionReducer() {
  return useReducer(genMotionReducer, initialGenMotionState);
}
