import { type ReactNode, createContext, useState, useContext, useEffect } from 'react';
import { useFetcher } from '@remix-run/react';
import * as Realm from 'realm-web';
import { useRealmApp } from '~/useRealmApp';
import { type GenMotionState, type GenMotionAction, useGenMotionReducer } from '~/stores/genmotion';
import {
  type PromptHistoryState, type PromptHistoryAction, usePromptHistoryReducer } from '~/stores/prompthistory';
import type { MotionItem } from '~/models/MotionItem';
import { useInterval } from '~/utils/useInterval';


type AppContext = {
  user?: Realm.User | null;
  setUser: (user: Realm.User | null) => void;
  message: string;
  setMessage: (message: string) => void;
  genMotionState: GenMotionState;
  genMotionDispatch: React.Dispatch<GenMotionAction>;
  promptHistoryState: PromptHistoryState;
  promptHistoryDispatch: React.Dispatch<PromptHistoryAction>;
};

export const AppContext = createContext<AppContext|null>(null);

export function useAppContext() {
  let ctx = useContext(AppContext);
  if (!ctx) {
    throw new Error('app context is null');
  }
  return ctx;
}


export function AppContextProvider({children}: {children: ReactNode}) {

  const app = useRealmApp();
  const [ user, setUser ] = useState<Realm.User | null>(app?.currentUser);
  const [ message, setMessage ] = useState<string>('');
  const [ genMotionState, genMotionDispatch ] = useGenMotionReducer();
  const [ promptHistoryState, promptHistoryDispatch ] = usePromptHistoryReducer();

  const value: AppContext = {
    user, setUser,
    message, setMessage,
    genMotionState, genMotionDispatch,
    promptHistoryState, promptHistoryDispatch
  };

  useInterval(() => {
    if (genMotionState.processing && genMotionState.sessionId) {
      app?.currentUser?.callFunction('getMotionItem', {
        sessionId: genMotionState.sessionId
      }).then((item: any) => {
        let data: MotionItem = item.data;
        genMotionDispatch({type: 'update-progress', progress: data.progress ?? 0});
        if (data.status === 'success') {
          genMotionDispatch({type: 'success', data: item});
          promptHistoryDispatch({type: 'update-item', history: {
            sessionId: data.sessionID,
            prompt: data.prompt ?? '',
            processing: false,
            isNew: true,
          }});
        }
      });
    }
  }, 1000);
  
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>);
}


export function useGenMotionState() {
  return useAppContext().genMotionState;
}


export function useGenMotionDispatch() {
  return useAppContext().genMotionDispatch;
}


export function usePromptHistoryState() {
  return useAppContext().promptHistoryState;
}


export function usePromptHistoryDispatch() {
  return useAppContext().promptHistoryDispatch;
}

// prompt 내용을 서버에서 받아옴
export function useGenMotionFetcher(addToHistoryOnFinish?: boolean) {

  const app = useRealmApp();
  const fetcher = useFetcher();
  const genMotionState = useGenMotionState();
  const genMotionDispatch = useGenMotionDispatch();
  const promptHistoryDispatch = usePromptHistoryDispatch();
  
  const submitGenerate = (prompt: string) => {
    if (!app.currentUser) {
      throw new Error('no current user');
    }

    if (genMotionState.processing) {
      throw new Error('already in processing');
    }
    
 
    fetcher.submit({
      _action: 'gen-motion',
      prompt,
      email: app.currentUser.customData.email as string
    }, {
      method: 'post',
      action: '/action',
      replace: true,
    });

    genMotionDispatch({ type: 'request', prompt });
  }

  const submitGenerateRandom = (prompt: string, sessionId: string, selectedIndex: number) => {
    if (!app.currentUser) {
      throw new Error('no current user');
    }

    if (genMotionState.processing) {
      throw new Error('already in processing');
    }
    

    fetcher.submit({
      _action: 'gen-motion-random',
      prompt,
      sessionId,
      selectedIndex: `${selectedIndex}`,
      email: app.currentUser.customData.email as string
    }, {
      method: 'post',
      action: '/action',
      replace: true,
    });

    genMotionDispatch({ type: 'request', prompt });
  }

  //useEffect로 데이터 패치 후 상태 업데이트 history에 추가
  useEffect(() => {
    if (fetcher.data) {
      if (fetcher.data.status !== 'success') {
        genMotionDispatch({
          type: 'failure', error: fetcher.data.message ?? '<unknown error>' });
      }
      if (fetcher.data.data?.session_id) {
        genMotionDispatch({
          type: 'update-sessionid', sessionId: fetcher.data.data.session_id});
        if (addToHistoryOnFinish) {
          promptHistoryDispatch({type: 'prepend', history: {
            sessionId: fetcher.data.data.session_id,
            prompt: fetcher.data.prompt,
            processing: true
          }});
        }
      }
    }
  }, [fetcher.data]);

  return { fetcher, submitGenerate, submitGenerateRandom };
}
