import { useReducer } from 'react';
import type { PromptHistory } from '~/models/PromptHistory';

/* PROMPT HISTORY STATE */

export type PromptHistoryState = {
  list: PromptHistory[];
};

export type PromptHistoryAction =
  | { type: 'reset' }
  | { type: 'add', history: PromptHistory }
  | { type: 'append', history: PromptHistory }
  | { type: 'prepend', history: PromptHistory }
  | { type: 'set', list: PromptHistory[] }
  | { type: 'update-item', history: PromptHistory }
  | { type: 'remove', history: PromptHistory }


function promptHistoryReducer(state: PromptHistoryState, action: PromptHistoryAction): PromptHistoryState {
  console.log(`[PROMPT HISTORY] action.type: ${action.type}`);
  switch (action.type) {
    case 'reset':
      return initialPromptHistoryState;
    case 'add':
      return { ...state, list: [ ...state.list, action.history ] };
    case 'append':
      return { ...state, list: [ ...state.list, action.history ] };
    case 'prepend':
      return { ...state, list: [ action.history, ...state.list ] };
    case 'set':
      return { ...state, list: action.list };
    case 'update-item':
      return { ...state, list: state.list.map(i => i.sessionId === action.history.sessionId ? action.history : i) };
    case 'remove':
      return { ...state, list: state.list.filter(i => i.sessionId !== action.history.sessionId) };
  }
};

const initialPromptHistoryState: PromptHistoryState = {
  list: []
};


export function usePromptHistoryReducer() {
  return useReducer(promptHistoryReducer, initialPromptHistoryState);
}

